@tailwind base;
@tailwind components;
@tailwind utilities;

@layer component {
  .decoration-no-skip-ink {
    text-decoration-skip-ink: none;
  }
  .sq-input-shadow {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 0px 2px rgba(0, 0, 0, 0.08);
  }
}

body {
  margin: 0;
}

#card-container {
  margin-bottom: 8px;
}

.global-skip-link,
.global-skip-link-destination {
  position: absolute;
  top: -40px; /* Moves off-screen */
  left: 10px;
  background: white;
  padding: 8px;
  text-decoration: none;
  border: 1px solid black;
  z-index: 1000;
}

.global-skip-link:focus {
  position: static;
  top: 10px;
}
